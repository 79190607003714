<template>
  <ContentWrapper>
    <div ref="observerElement" class="observer-element" />

    <VAppBarMobile class="mobile-fixed-header" :class="{ 'fixed-top': true, hidden: isHidden }">
      <template #prepend>
        <div class="menu-magic-icon ml-4" :class="{ open: isMainMenuOpen }" @click="toggleMainMenu">
          <div class="menu-magic-icon__line" />
          <div class="menu-magic-icon__line" />
          <div class="menu-magic-icon__line" />
        </div>
      </template>
      <GlobalMainMenu
        :phone="headerFooter.header?.phone || ''"
        :b2blink="headerFooter.header?.b2b_link || ''" />

      <v-app-bar-title class="d-flex justify-center">
        <nuxt-link to="/">
          <v-img
            width="200"
            src="https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8"
            srcset="https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8 1x, https://epd.directus.app/assets/8932063c-5a11-4ca1-88fe-8de66054f8b8 2x"
            alt="Containerdienst.de" />
        </nuxt-link>
      </v-app-bar-title>

      <ClientOnly>
        <ButtonHeader class="no-borders" @click="openSidebar">
          <v-badge
            :content="cart.products.reduce((accu, curr) => accu + curr.quantity, 0)"
            text-color="white"
            offset-x="24"
            offset-y="-8"
            bordered>
            <v-img
              class="pr-5"
              width="60"
              :src="IconHeaderCartContainer"
              :alt="t('your_shopping_cart')" />
          </v-badge>
        </ButtonHeader>
      </ClientOnly>
    </VAppBarMobile>
  </ContentWrapper>
</template>

<script setup lang="ts">
import IconHeaderCartContainer from '@/assets/icons/header/header-cart-container.svg'
import { ACTIVE_SIDEBAR_TYPE } from '~/types/enums'

const sidebarStore = useSidebarStore()
const headerFooterStore = useHeaderFooterStore()
const cartStore = useCartStore()

const { t } = useI18n()
const { headerFooter } = storeToRefs(headerFooterStore)
const { isMainMenuOpen } = storeToRefs(sidebarStore)
const { cart } = storeToRefs(cartStore)

if (headerFooter.value.header === null) await headerFooterStore.getHeaderFooterData()
cartStore.getCart()

const openSidebar = () => sidebarStore.openGlobalSidebar(ACTIVE_SIDEBAR_TYPE.CART)
const toggleMainMenu = () => sidebarStore.toggleMainMenu()

const isHidden = ref(false)
const observerElement = ref(null)

onMounted(() => {
  const observer = new IntersectionObserver(
    entries => {
      const entry = entries[0]
      isHidden.value = !entry.isIntersecting && !isMainMenuOpen.value
    },
    {
      root: null,
      rootMargin: '58px 0px -100%',
    },
  )

  if (observerElement.value) {
    observer.observe(observerElement.value)
  }

  onUnmounted(() => {
    if (observerElement.value) {
      observer.unobserve(observerElement.value)
    }
  })
})
</script>

<style scoped lang="scss">
.no-borders {
  border: none !important;
}
.mobile-fixed-header {
  position: fixed !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it is above other content */
  transition: transform 0.3s ease-in-out;
}
.hidden {
  transform: translateY(-100%) !important;
}
.menu-magic-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 14px;
  width: 14px;
  cursor: pointer;
  .menu-magic-icon__line {
    width: 20px;
    height: 2px;
    background-color: #000;
    transition: transform 0.3s ease-in-out;
  }
  &.open {
    .menu-magic-icon__line:nth-child(1) {
      transform: rotate(45deg) translate(2px, 2px);
    }
    .menu-magic-icon__line:nth-child(2) {
      transform: rotate(-45deg) translate(2px, -2px);
    }
    .menu-magic-icon__line:nth-child(3) {
      opacity: 0;
    }
  }
}
</style>
